import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
import Letter from "../components/Letter";
import Signature from "../components/Signature";
import Testimonial from "../components/Testimonial";
import GradientText from "../components/GradientText";
import InvisibleAnchor from "../components/InvisibleAnchor";
import Ref from "../components/Reference";
import GumroadCTA from "../components/GumroadCTA";
import Opa from "../components/Opacity";
import TechnologiesGrid from "../components/TechnologiesGrid";
import RedeemForm from "../components/RedeemForm";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div align="center" style={{
      marginTop: `2em`
    }}>
      <h1>{`Redeem your license key`}</h1>
    </div>
    <p>{`Thank you for purchasing Bedrock!`}</p>
    <p>{`Find your license key in the purchase confirmation email.`}</p>
    <p>{`You will be added to the GitHub repository with all the code for Bedrock and get your invite link to the Discord community for you and your team.`}</p>
    <p>{`Every license key `}<strong parentName="p">{`can only be redeemed once`}</strong>{`! That means only one person from your team will be added to the Bedrock repository. That person should download the latest release of Bedrock and then set up a new repository based on Bedrock for your product that they add your entire team to.`}</p>
    <RedeemForm mdxType="RedeemForm" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      